import React, { FC, RefObject, useRef, useState } from "react";
import axios from "axios";
import { Input, TextField } from "@material-ui/core";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Cookies from "universal-cookie";

import SecureLogin from "../components/SecureLogin"

import { sendCloudCastles } from '../components/Helpers'

function AddressAutoComplete({ endpoint,placeholder }) {
  const inputRef = useRef(null);
  const antInputRef = useRef(null);
  const [country, setCountry] = useState("US");
  const [address1,setAddress1] = React.useState("");
  const [address2,setAddress2] = React.useState("");
  const [city,setCity] = React.useState("");
  const [state,setState] = React.useState("");
  const [zip,setZip] = React.useState("");
  const { ref: materialRef } = usePlacesWidget({
    apiKey: "AIzaSyAvRViF7bYdQ50zH5jUblEcZlyXndw7aJs",
    onPlaceSelected: (place) => console.log(place),
    inputAutocompleteValue: "country",
    options: {
      componentRestrictions: { country },
    },
  });

  const mystyle = {
      width: "100vw",
      maxWidth:"340px",
      height:"45px",
      borderRadius: "40.3653px"
    };

  const cookies = new Cookies();

  const handleSumit = (address1,address2,city,state,zip,country) => {
    // use to make sure page isn't reloaded when we send off content
    // event.preventDefault();

    const addressData = {
      country:country,
      address1:address1,
      address2:address2,
      city:city,
      state:state,
      zip:zip  
    };

    // const GATSBY_APP = process.env.CASTLE_API_URL
    // console.log(`test`)
    // console.log(GATSBY_APP)
    // console.log(`${process.env.CASTLE_API_URL}`)

    const castle_key = cookies.get("castle_access");

    
    axios
      .put(endpoint, addressData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + castle_key,
        },
      })
      .then((response) => {
          console.log(response.data);
  //       setEmailError(error.response.data.email)

        //   const castle_key = response.data["key"];          
        //   cookies.set("castle_key", castle_key, { path: "/", httpOnly:true });

        //   console.log("WTFError");
        //   console.log(castle_key);
          
        //   axios
        //     .put("http://127.0.0.1:8000/v1/web_registration/", userData, {
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: "token " + castle_key,
        //       },
        //     })
        //     .then((response) => {
        //         console.log(response.data);
        //         props.nextStep()
        //       })
        //     .catch((error) => {
        //       console.log(error.response.data);
        //       setEmailError(error.response.data.email)
        //     });
        })
      .catch((error) => {
        console.log(error.response);
        // setEmailError(error.response.data.email)
      });
    };

  return (
    <div>
      <header>
        {/*<span style={{ color: "black" }}>Standard HTML</span>*/}
        <Autocomplete
          style={mystyle}
          ref={inputRef}
          placeholder={placeholder}
          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          apiKey={"AIzaSyAvRViF7bYdQ50zH5jUblEcZlyXndw7aJs"}
          onPlaceSelected={(selected, a, c) => {
            // alert(selected, a, c);
            console.log(selected["address_components"])
            console.log(selected["address_components"][0]["long_name"]);
            console.log(selected["address_components"][1]["long_name"]);
            console.log(selected["address_components"][2]["long_name"]);
            console.log(selected["address_components"][3]["long_name"]);
            console.log(selected["address_components"][4]["long_name"]);
            console.log(selected["address_components"][5]["long_name"]);
            console.log(selected["address_components"][6]["long_name"]);
            // console.log(selected["address_components"][7]["long_name"]);

            setCountry(selected["address_components"][5]["long_name"])
            setAddress1(selected["address_components"][0]["long_name"] + " " + selected["address_components"][1]["long_name"])
            // setAddress2()
            setCity(selected["address_components"][2]["long_name"])
            setState(selected["address_components"][3]["long_name"])
            setZip(selected["address_components"][6]["long_name"])


            const addressData = {
              country:"USA",
              address1:selected["address_components"][0]["long_name"] + " " + selected["address_components"][1]["long_name"],
              address2:"",
              city:selected["address_components"][2]["long_name"],
              state:selected["address_components"][3]["long_name"],
              zip_code:selected["address_components"][6]["long_name"]  
            };



            sendCloudCastles(endpoint,addressData)

            // handleSumit()

          }}
          options={{
            types: ["geocode", "establishment"],
            componentRestrictions: { country },
          }}
          defaultValue=""
        />
{/*        <select
          onChange={(v) => {
            setCountry(v.target.value);
          }}
          style={{ color: "black", display: "none" }}
        >
          <option key="1" value="us">
            Us
          </option>
        </select>*/}
{/*        <div style={{ width: "250px" }}>
          <span style={{ color: "black" }}>Material UI</span>
          <Input
            fullWidth
            color="secondary"
            inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
              <Autocomplete
                apiKey={"AIzaSyAvRViF7bYdQ50zH5jUblEcZlyXndw7aJs"}
                {...props}
                onPlaceSelected={(selected) => console.log(selected)}
              />
            )}
          />
        </div>
        <div style={{ width: "250px", marginTop: "20px" }}>
          <span style={{ color: "black" }}>Material UI</span>
          <TextField
            fullWidth
            color="secondary"
            variant="outlined"
            inputRef={materialRef}
          />
        </div>*/}
{/*        <div style={{ width: "250px", marginTop: "20px" }}>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label style={{ color: "black" }}>Bootstrap</Form.Label>
              <Form.Control type="email" ref={bootstrapRef} />
            </Form.Group>
          </Form>
        </div>
        <div style={{ width: "250px", padding: "20px 0" }}>
          <span style={{ color: "black" }}>Ant Design</span>
          <AntInput
            ref={(c) => {
              antInputRef.current = c;
              if (c) antRef.current = c.input;
            }}
            size="large"
          />
        </div>*/}
      </header>
    </div>
  );
}

export default AddressAutoComplete;