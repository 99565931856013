import React from "react"
import Lottie from "react-lottie"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import ResponsiveAppBar from "../components/ResponsiveAppBar"
import SocialAndAddressInput from "../components/SocialAndAddressInput"
import SEO from "../components/seo"

const Authentication = () => {

  return (
    <>
      <SEO
        title="Login"
        keywords="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
      />{" "}
      <ResponsiveAppBar />

        <SocialAndAddressInput />

    </>
  )
}

export default Authentication

const Body = styled.div`
  position: relative;
`