import React, { FC, RefObject, useRef, useState } from "react";
import axios from "axios";
import { Input, TextField } from "@material-ui/core";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Cookies from "universal-cookie";
import styled from "styled-components";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Eye from "../images/Eye.png"
import EyeInvisible from "../images/EyeInvisible.png"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { hitCCBE,loginCC,sendCloudCastles,getBothTokens } from './Helpers'
import AddressAutoComplete from "../components/AddressAutoComplete"

function SocialAndAddressInput({ endpoint }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [response, setResponse] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const thankYou = () => {
    ////// TRYING TO MAKE THIS GO BACK TO THE SAME PAGE

      // const location = useLocation();
      // const cookies = new Cookies();
      const url = typeof window !== 'undefined' ? window.location.href : '';
      // const cookies = new Cookies();
      // cookies.set("castle_return_location", url, { path: "/" });
      window.location.href = "/thank-you-survey1"
  }

  function taxInput(endpoint,to_cc_data,rtype) {

  const cookies = new Cookies();

  var castle_access = cookies.get("castle_access");

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  myHeaders.append("Authorization", "Bearer " + castle_access);

  var requestOptions = {
    method: rtype,
    headers: myHeaders,
    body: to_cc_data,
    redirect: 'follow'
  };

  fetch(endpoint, requestOptions)
    .then((response) =>  {
      console.log(response)
     if(response.status!==200){return (response.statusText)}

      return response.json().then((data_r) => {

      thankYou()

      return data_r;
      
      // to_castle_return_location()
    })  
    .catch((error) => {
      console.log("r.json()",error)
      // return error
      });
  });

}


  const sendInInfo = () => {
    console.log("test")
    var rawData = JSON.stringify({
      "ssn": password,
      // "phone": phoneNumber,
      // "first_name": first_name,
      // "last_name": last_name,
      });
     taxInput(`https://alpha.cloudcastles.io/v1/web_registration/`,rawData,'PUT')
    // console.log("profile",profile)

  }

  // const handleSumit = (event) => {
  //   // use to make sure page isn't reloaded when we send off content
  //   event.preventDefault();

  //     let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //     if (re.test(username)) {
  //       console.log("approved")
  //     var userData =  JSON.stringify({
  //       ssn: password,
  //       username: username,
  //     });

  //     console.log(userData);
  //     loginCC(`${process.env.CASTLE_API_URL}/api/token/`, userData,'POST').then(r => {setResponse(r)}).catch(error => console.log(error))
      
  //     } else {
  //       console.log(username)

  //       setResponse("Please set a valid email")      
  //     }
  // };

  // const changePWView = () => {
  //   setShowPassword(!showPassword)
  // }

  return (
    <Body>
    <Hero3>
    <H1Text>Add Your Info To </H1Text>
    <H1Text> Get Your Tax Benefits</H1Text>
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      <ListItem>
      </ListItem>
      <Divider component="li" />
      <li>
        <Typography
          sx={{ mt: 0.5, ml: 2 }}
          color="text.secondary"
          display="block"
          variant="caption"
        >
          
        </Typography>
      </li>
    </List>
    <H2Text>Social Security</H2Text>
        <CTAInput
          type="password"
          placeholder="Social Security Number"
          type={showPassword ? "text" : "password"}
          onChange={(event) => setPassword(event.target.value)}
        />
        {/*{showPassword ? <VisibilityOffIcon sx={{ input: { cursor: 'pointer' } }} onClick={changePWView}></VisibilityOffIcon> : <VisibilityIcon sx={{ input: { cursor: 'pointer' } }} onClick={changePWView}></VisibilityIcon>}*/}
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      <ListItem>
      </ListItem>
      <Divider component="li" />
      <li>
        <Typography
          sx={{ mt: 0.5, ml: 2 }}
          color="text.secondary"
          display="block"
          variant="caption"
        >
          
        </Typography>
      </li>
    </List>
      <AddressAutoComplete placeholder={"Mailing address (this is not public)"} endpoint={`https://alpha.cloudcastles.io/v1/web_registration/`} />     
      <CTAButton type="submit" onClick={sendInInfo}>  Submit  </CTAButton>        
      </Hero3>
    </Body>
  );
}

export default SocialAndAddressInput;

const Body = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background-color:#F0F2F5;
  @media only screen and (max-width: 825px) and (min-width: 100px) {
    width:100%;
  }
`


const DetailText = styled.h2`
  position: relative;
  width: 100%;
  margin:5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12.7436px;
  line-height: 151%;
  color: #333333;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`

const FP = styled.a`
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  text-decoration:none;
  color: #0060B6;
`

const FPDiv = styled.div`
  margin:5px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`


const SmallButton = styled.button`
  background-image: url(${Eye});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  // flex-direction: row;
  align-items: left;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  // padding-bottom: 30px;
  font-family: Poppins;
  text-align: left; 
  margin-left: 30px;
  margin-right: auto;
  font-style: thin;
  // font-weight: bold;
  font-size: 14.1461px;
  // line-height: 24px;
  width:108px;
  height:28px;
  // justify-content: left;
  justify-content: center;
  // color: "#4D566F";

  background-color: clear;
  box-shadow: 0px 3.58802px 22.4251px clear;
  // border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
`


const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-left: 10px;
  // font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;
  width:200px;
  height:40px;
  margin-top:15px;
  margin-bottom:15px;

  color: #ffffff;

  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAInput = styled.input`
  display: flex;
  padding: 16.1461px 30.7772px;

  // font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 20px;
  margin:2px;
  color: black;

  width:300px;

  border: 0.897006px solid #333333;
  box-sizing: border-box;
  // filter: drop-shadow(0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26));
  border-radius: 10.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
const CTAForm = styled.form`
  margin: auto;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
`

const H2Text = styled.h1`
  // font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20.7436px;
  text-align: center; 
  margin-top:5px;
`

const H1Text = styled.h1`
  // font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30.7436px;
  text-align: center; 
  margin-top:10px;
`

const Hero2 = styled.div`
  height:419px;
  position:relative;
  width:724px;
`

const Hero3 = styled.div`
  // height:419px;
  position:relative;
  width:724px;
  // margin-top:  30px;
  // margin-bottom:  30px;
  border-radius:33px;
  
  display: flex;
  // padding:25px;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  background-color:white;
  overflow:auto;

  // max-width: 330px;
  // height: 500px;
  // margin-left:30px;
  // // margin-right:10px;
  // position: relative;
  // display: grid;
  // grid-template-rows: 1fr 1fr;
  // background: rgba(255, 255, 255, .7);
  // border-radius: 33px;

  alignItems: stretch;
  // top: 0px;
  transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  transition: all 0.15s ease-in-out;
  box-shadow: 0px 3.58802px 22.4251px rgba(0.55,0.75, 0.95, 0.26);
  // &:hover {
  //   // transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
  //     top: -15px;
      
  //     background: white;
  //     box-shadow: 0px ;

  // }

  // margin-left:  230px;
  // margin-right:  230px;
  @media (max-width: 1132px) {
    // flex-direction: column;
    // margin-top:50px;
    
    
  }
  @media only screen and (max-width: 825px) and (min-width: 100px) {
    width:100%;
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // margin-top:100px;
    // margin-top:50px;
    // width:90%;
  }

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  
`
